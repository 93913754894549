import { Stack } from "@mui/material";
import {
  ACCEPT_OR_REJECT,
  ADD_UTR_TO_PAYIN_TXN,
  GET_RESPONSE_CODE,
  SEND_CALLBACK,
} from "./Constants";

import axios from "axios";
import Cookies from "js-cookie";
import swal from "sweetalert";

import ReactDOM from "react-dom/client";
import ReportIssuePayin from "../Reports/ReportIssuePayin";

export const columnForPayoutTable = [
  {
    headerName: "Reference Id",
    field: "referenceId",
    minWidth: 150,
  },
  {
    headerName: "Transaction Id",
    field: "txnId",
    minWidth: 150,
  },
  {
    headerName: "Amount",
    field: "amount",
    minWidth: 150,
  },
  {
    headerName: "Transaction Status",
    field: "txnStatus",
    minWidth: 150,
  },
  {
    headerName: "Transaction Date",
    field: "createdDate",
    minWidth: 150,
  },
  {
    headerName: "Transaction Request",
    field: "txnRequestTime",
    minWidth: 200,
  },
  {
    headerName: "Transaction Time",
    field: "createdTime",
    minWidth: 150,
  },

  {
    headerName: "Transaction Response",
    field: "txnResponseTime",
    minWidth: 200,
  },
  {
    headerName: "Callback Received",
    field: "callbackReceiveTime",
    minWidth: 200,
  },
  {
    headerName: "Callback Sent To Merchant",
    field: "callbackSendTime",
    minWidth: 250,
  },

  {
    headerName: "Callback Acknowledgment Received",
    field: "merchantcallbackResponseTime",
    minWidth: 250,
  },
  {
    headerName: "Callback Json Received from Bank",
    field: "callbackJsonExist",
    minWidth: 250,
  },
  {
    headerName: "Merchant Callback Json",
    field: "merchantcallbackResponse",
    minWidth: 250,
  },

  {
    headerName: "Vendor Name",
    field: "fullName",
    minWidth: 150,
  },
  {
    headerName: "Mobile Number",
    field: "mobileNo",
    minWidth: 150,
  },
  {
    headerName: "e-Mail Id",
    field: "email",
    minWidth: 150,
  },
  {
    headerName: "RRN",
    field: "rrn",
    minWidth: 150,
  },
  {
    headerName: "Particular(A/C or VPA)",
    field: "accountNumber",
    minWidth: 150,
  },
  {
    headerName: "IFSC Code",
    field: "ifscCode",
    minWidth: 150,
  },
  {
    headerName: "Request Status",
    field: "payoutStatus",
    minWidth: 150,
  },
  {
    headerName: "To Customer/Vendor Name",
    field: "payoutFor",
    minWidth: 150,
  },
  {
    headerName: "Transfer Type",
    field: "transferType",
    resizable: true,
    minWidth: 150,
  },
  {
    headerName: "Remark",
    field: "remark",
    resizable: true,
    minWidth: 150,
  },
];

export const columnForPayin = [
  {
    headerName: "Payment Type",
    field: "paymentType",
    minWidth: 120,
  },
  {
    headerName: "Order-No",
    field: "orderNo",

    minWidth: 150,
  },
  {
    headerName: "Transaction ID",
    field: "txn_id",
    minWidth: 200,
  },
  {
    headerName: "Amount",
    field: "amount",

    minWidth: 150,
  },
  {
    headerName: "Actual Amount",
    field: "actualAmount",

    minWidth: 150,
  },
  {
    headerName: "Transaction Status",
    field: "txnStatus",

    minWidth: 150,
  },
  {
    headerName: "Transaction Date",
    field: "txnDate",
    minWidth: 150,
  },
  {
    headerName: "Request Time",
    field: "txnTime",
    minWidth: 150,
  },

  {
    headerName: "Sent To Bank ",
    field: "sentToBankDate",

    minWidth: 150,
  },
  {
    headerName: "Response From Bank ",
    field: "ackFromBankDate",

    minWidth: 150,
  },
  {
    headerName: "QR Sent To Merchant",
    field: "qrSentToMerchantDate",

    minWidth: 150,
  },
  {
    headerName: "Callback Received From Bank ",
    field: "CallbackReceivedFromBank",
    minWidth: 150,
  },
  {
    headerName: "Callback Sent To Merchant",
    field: "callbackSentToMerchantDate",

    minWidth: 150,
  },
  {
    headerName: "Acknowledgement Received From Merchant",
    field: "ackReceivedFromMerchantDate",

    minWidth: 150,
  },
  {
    headerName: "Currency",
    field: "currency",

    minWidth: 150,
  },

  {
    headerName: "Response Date",
    field: "responseTime",

    minWidth: 150,
  },
  {
    headerName: "UTR-No",
    field: "custRefNo",

    minWidth: 150,
  },

  {
    headerName: "Payer VPA",
    field: "userVpa",

    minWidth: 150,
  },
  {
    headerName: "Mobile No",
    field: "mobileNo",

    minWidth: 150,
  },
  {
    headerName: "e-Mail ID",
    field: "email",

    minWidth: 150,
  },

  {
    headerName: "Transaction Response Code",
    field: "txnRespCode",

    minWidth: 150,
  },
  {
    headerName: "Bank Response Code",
    field: "evokTxnCodeMode",

    minWidth: 150,
  },
  {
    headerName: "Remark Description",
    field: "remark",

    minWidth: 150,
  },
  {
    headerName: "IFSC Code",
    field: "customerIfsc",

    minWidth: 150,
  },
  {
    headerName: "Bank Location",
    field: "bankLocation",

    minWidth: 150,
  },
  {
    headerName: "Customer IP address",
    field: "customerIp",

    minWidth: 150,
  },
  {
    headerName: "Customer Location",
    field: "customerLocation",

    minWidth: 150,
  },
  {
    headerName: "Send Callback",
    field: "Send Callback",
    minWidth: 200,
    renderCell: (params) => {
      const token = Cookies.get("access_token");

      const handelSendCallback = async () => {
        const txnID = params.row.txn_id?.split("-")[1];
        const txnStatus = params.row.txnStatus;

        try {
          const response = await axios.post(SEND_CALLBACK + txnID, "", {
            headers: {
              Authentication: "Bearer " + token,
            },
          });

          if (response?.data?.statusCode === 200) {
            swal({
              title: "Success!",
              text: "Callback Send Successfully.",
              icon: "success",
            });
          } else {
            swal({
              title: "Alert!",
              text: "Failed To Send Callback.",
              icon: "error",
            });
            // navigate('/Reports')
          }
          // navigate('/Reports')
        } catch (error) {
          console.log(error);
        }
      };

      return (
        <Stack direction="row" spacing={5}>
          {params?.row?.txnStatus === "failed" ||
          params?.row?.txnStatus === "success" ? (
            <button
              variant="outlined"
              color="warning"
              size="small"
              className="btn btn-sm btn-primary m-2"
              onClick={handelSendCallback}
            >
              Send Callback
            </button>
          ) : null}
        </Stack>
      );
    },
  },
  {
    headerName: "Action",
    field: "Action",
    minWidth: 350,
    renderCell: (params) => {
      const token = Cookies.get("access_token");

      const AddUtrToPayin = async (payload) => {
        try {
          const response = await axios.post(ADD_UTR_TO_PAYIN_TXN, payload, {
            headers: {
              Authentication: "Bearer " + token, // Corrected from "Authentication" to "Authorization"
            },
          });

          if (response?.data?.statusCode === 200) {
            swal({
              title: "Success",
              text: "UTR Number Added Successfully.",
              icon: "success",
            });
          } else if (response?.data?.statusCode === 304) {
            swal({
              title: "Failed",
              text: response?.data?.message,
              icon: "error",
            });
          } else {
            swal({
              title: "Failed",
              text: "Error Occured While Adding The UTR Number.",
              icon: "error",
            });
          }
        } catch (error) {
          console.log(error.message);
        }
      };

      const handleOpenReportPopup = (data) => {
        const wrapper = document.createElement("div"); // Create a wrapper for the React component
        const root = ReactDOM.createRoot(wrapper);
        root.render(<ReportIssuePayin data={data} />);

        swal({
          title: "Add UTR Number",
          content: wrapper, // Use the wrapper as the content
          buttons: {
            cancel: "Cancel",
            submit: {
              text: "Submit",
              closeModal: false, // Keeps the modal open until manually closed
            },
          },
        }).then((value) => {
          if (value === "submit") {
            // Get the input values using plain JavaScript
            const amount =
              document.getElementById("reportIssuesInput1").textContent;
            const orderNo =
              document.getElementById("reportIssuesInput2").textContent;
            const txn_id =
              document.getElementById("reportIssuesInput3").textContent;
            const status =
              document.getElementById("reportIssuesInput5").textContent;
            const txnDate =
              document.getElementById("reportIssuesInput6").textContent;
            const utrNumber =
              document.getElementById("reportIssuesInput4").value;
            const vpa =
              document.getElementById("reportIssuesInput7").value;

            if (utrNumber.length !== 12) {
              swal({
                title: "Alert!",
                text: "Please Enter Valid UTR Number (12 Characters).",
                icon: "warning",
              });
              return;
            }

            const payload = {
              action: "testing",
              amount: amount,
              orderNumber: orderNo,
              status: status,
              txnDate: txnDate,
              txnId: txn_id,
              utr: utrNumber,
              vpa: vpa,
            };
            console.log(payload);

            AddUtrToPayin(payload);

            swal.close();
          }
        });
      };

      async function HandleAcceptOrReject(orderNumber, type) {
        swal({
          title: "Are you sure?",
          text: "Do you Really want to Perform this?",
          icon: "info",
          buttons: true,
          dangerMode: true,
        }).then(async (willSubmit) => {
          if (willSubmit) {
            // User confirmed, submit the request
            try {
              const payload = {
                orderNo: orderNumber,
                status: type,
              };

              const response = await axios.post(ACCEPT_OR_REJECT, payload, {
                headers: {
                  Authentication: "Bearer " + token, // Corrected from "Authentication" to "Authorization"
                },
              });
              if (response?.data?.statusCode === 200) {
                swal({
                  title: "Success!",
                  text:
                    type === "ACCEPT"
                      ? "Accepted Successfully."
                      : "Rejected Successfully.",
                  icon: "success",
                });
                // fetchData();
                // getLiveTrackingReport();
              } else if (response?.data?.statusCode === 304) {
                swal({
                  title: "Alert!",
                  text:
                    type === "ACCEPT"
                      ? "Failed To Accept."
                      : "Failed To Reject.",
                  icon: "error",
                });
                // navigate('/Reports')
              } else {
                swal({
                  title: "Alert!",
                  text: "Something Went Wrong.",
                  icon: "error",
                });
                // navigate('/Reports')
              }

              //   setFilterRecords(response);
            } catch (error) {
              console.log(error);
            } finally {
              // setIsSubmitting(false);
            }
          } else {
            // User canceled the submission
            swal("Submission canceled!");
          }
        });
      }

      return (
        <>
          {params?.row?.txnStatus.toLowerCase() == "warning" && (
            <>
              <button
                variant="outlined"
                color="warning"
                size="small"
                className="btn btn-sm btn-primary m-2"
                onClick={() =>
                  HandleAcceptOrReject(params?.row?.orderNo, "ACCEPT")
                }
              >
                Accept
              </button>
              <button
                className="btn btn-sm btn-danger m-2"
                onClick={() =>
                  HandleAcceptOrReject(params?.row?.orderNo, "REJECT")
                }
              >
                Reject
              </button>
            </>
          )}
          {params?.row?.domain === "ZenePe" && (
            <a
              className="link text-primary cursor-pointer"
              onClick={() => handleOpenReportPopup(params?.row)}
            >
              Add UTR
            </a>
          )}
        </>
      );
    },
  },
  {
    headerName: "Response Code",
    field: "ShowResponseCode",
    minWidth: 350,
    renderCell: (params) => {
      const token = Cookies.get("access_token");

      async function HandleShowResponseCode(id) {
        console.log(id);
        // swal({
        //   title: "Are you sure?",
        //   text: "Do you Really want to Perform this?",
        //   icon: "info",
        //   buttons: true,
        //   dangerMode: true,
        // }).then(async (willSubmit) => {
        //   if (willSubmit) {
        // User confirmed, submit the request
        try {
          const response = await axios.get(
            GET_RESPONSE_CODE + id?.split("-")[1],
            {
              headers: {
                Authentication: "Bearer " + token, // Corrected from "Authentication" to "Authorization"
              },
            }
          );
          console.log(response);
          if (response?.data?.statusCode === 200) {
            let alertColorClass;

            if (
              response?.data?.data?.includes(205) ||
              response?.data?.data?.includes(206) ||
              response?.data?.data?.includes(207)
            ) {
              alertColorClass = "alert alert-success"; // Green color for 205, 206, 207
            } else {
              alertColorClass = "alert alert-danger"; // Red color for others
            }

            swal({
              title: "Response Code",
              content: {
                element: "div",
                attributes: {
                  innerHTML: `<div class="${alertColorClass}" role="alert">${response?.data?.data}</div>`,
                },
              },
              className: "custom-swal-popup",
            });
          } else {
            swal({
              title: "Alert!",
              text: "Something Went Wrong.",
              icon: "error",
            });
            // navigate('/Reports')
          }

          //   setFilterRecords(response);
        } catch (error) {
          console.log(error);
        }
        //   } else {
        //     // User canceled the submission
        //     swal("Submission canceled!");
        //   }
        // });
      }

      return (
        <>
          {params?.row?.txn_id && (
            <Stack direction="row" spacing={5}>
              <button
                variant="outlined"
                color="warning"
                size="small"
                className="btn btn-primary btn-sm m-2"
                onClick={() => HandleShowResponseCode(params?.row?.txn_id)}
              >
                View
              </button>
            </Stack>
          )}
        </>
      );
    },
  },
];

export const columnForVendorTable = [
  {
    headerName: "Vendor Name",
    field: "fullName",
    minWidth: 150,
  },
  {
    headerName: "e-Mail Id",
    field: "email",
    minWidth: 250,
  },
  {
    headerName: "Payer VPA",
    field: "userVPA",
    minWidth: 150,
  },
  {
    headerName: "Mobile Number",
    field: "mobile",
    minWidth: 150,
  },
  {
    headerName: "Vendor Status",
    field: "vendorStatus",
    minWidth: 150,
  },
];

export const columnForsettlementAdjustmentReport = [
  {
    headerName: "Merchant ID",
    field: "mid",
    minWidth: 150,
  },
  {
    headerName: "Transaction Status",
    field: "txnStatus",
    minWidth: 150,
  },
  {
    headerName: "Amount",
    field: "netAmount",
    minWidth: 150,
  },
  {
    headerName: "Create Date",
    field: "createDate",
    minWidth: 150,
  },
];

export const columnForDepositTable = [
  {
    headerName: "Deposit Date",
    field: "Deposit Date",
    minWidth: 150,
  },
  {
    headerName: "Deposit Method",
    field: "Deposit Method",
    minWidth: 150,
  },
  {
    headerName: "Amount",
    field: "Amount",
    minWidth: 150,
  },
];

export const columnForEKYCTable = [
  {
    headerName: "Request For",
    field: "RequestFor",
    minWidth: 150,
  },
  {
    headerName: "Merchant Id",
    field: "merchantId",
    minWidth: 150,
  },

  {
    headerName: "Date & Time",
    field: "date",
    minWidth: 150,
  },
  {
    headerName: "Amount",
    field: "amount",
    minWidth: 150,
  },
];

export const columnForWithdrawTable = [
  {
    headerName: "Created Date",
    field: "createDate",
    minWidth: 150,
  },
  {
    headerName: "Created Time",
    field: "createTime",
    minWidth: 150,
  },
  {
    headerName: "Transaction Date",
    field: "transactionDate",
    minWidth: 150,
  },
  {
    headerName: "UTR Number",
    field: "utr",
    minWidth: 150,
  },

  {
    headerName: "Amount",
    field: "amount",
    minWidth: 150,
  },
  {
    headerName: "Actual Amount",
    field: "requestedAmount",
    minWidth: 150,
  },
  {
    headerName: "Service Charge",
    field: "serviceCharge",
    minWidth: 150,
  },
  {
    headerName: "GST on Service",
    field: "gstCharge",
    minWidth: 150,
  },
  {
    headerName: "Transfer Type",
    field: "transferType",
    minWidth: 150,
  },
  {
    headerName: "Transfer Mode",
    field: "virtualAccountType",
    minWidth: 150,
  },

  {
    headerName: "Transaction Status",
    field: "status",
    minWidth: 150,
  },
  {
    headerName: "Remark",
    field: "remark",
    minWidth: 150,
  },
];

export const columnForPendingWithdrawTable = [
  {
    headerName: "Created Date",
    field: "createDate",
    minWidth: 150,
  },

  {
    headerName: "Amount",
    field: "amount",
    minWidth: 150,
  },

  {
    headerName: "Transfer Type",
    field: "transferType",
    minWidth: 150,
  },
  {
    headerName: "Transfer Mode",
    field: "virtualAccountType",
    minWidth: 150,
  },

  {
    headerName: "Transaction Status",
    field: "status",
    minWidth: 150,
  },
];

export const columnForReconciliationTable = [
  {
    headerName: "Date",
    field: "date",
    minWidth: 150,
  },
  {
    headerName: "UTR Number",
    field: "switchRrn",
    minWidth: 150,
  },

  {
    headerName: "Transaction ID",
    field: "extId",
    minWidth: 150,
  },
  {
    headerName: "Amount",
    field: "amount",
    minWidth: 150,
  },
  {
    headerName: "Transaction Status ",
    field: "transactionStatus",
    minWidth: 150,
  },
  {
    headerName: "Payer VPA / UPI ID",
    field: "payerVpa",
    minWidth: 150,
  },
  // {
  //   headerName: "Transaction Status",
  //   field: "status",
  //
  //   minWidth: 150,
  // },
  // {
  //   headerName: "Action",
  //   field: "action",
  //   minWidth: 150,
  // },
];

export const columnForReleaseReport = [
  // {
  //   headerName: "Date",
  //   field: "createDate",
  //   minWidth: 150,
  // },
  // {
  //   headerName: "Merchant ID",
  //   field: "mid",
  //   minWidth: 150,
  // },
  {
    headerName: "Created Date",
    field: "createDate",
    minWidth: 150,
  },
  {
    headerName: "Amount",
    field: "amount",
    minWidth: 150,
  },
  {
    headerName: "Transaction Date",
    field: "txnDate",
    minWidth: 150,
  },
  {
    headerName: "UTR Number",
    field: "rrn",
    minWidth: 150,
  },
  {
    headerName: "Release From",
    field: "releaseFrom",
    minWidth: 150,
  },
];

export const columnForSattlementTable = [
  {
    headerName: "Order Number",
    field: "orderNo",
    minWidth: 150,
  },
  {
    headerName: "Created Date",
    field: "createDate",
    minWidth: 150,
  },
  {
    headerName: "Created Time",
    field: "createTime",
    minWidth: 150,
  },
  {
    headerName: "Pay In Amount",
    field: "payinAmount",
    minWidth: 150,
  },
  {
    headerName: "Service Charge Amount",
    field: "serviceChargeAmount",
    minWidth: 150,
  },
  {
    headerName: "GST Amount",
    field: "gstAmount",
    minWidth: 150,
  },

  {
    headerName: "Settled Amount",
    field: "netAmount",
    minWidth: 150,
  },
  {
    headerName: "Hold Amount",
    field: "holdAmount",
    minWidth: 150,
  },
  {
    headerName: "Deposit Amount",
    field: "deposoitAmount",
    minWidth: 150,
  },
  {
    headerName: "Net Amt to Wallet",
    field: "SettledAmountMod",
    minWidth: 150,
  },

  {
    headerName: "Transaction ID",
    field: "txnId",
    minWidth: 150,
  },

  {
    headerName: "Remark",
    field: "remark",
    minWidth: 150,
  },
  {
    headerName: "Collection method",
    field: "collectionMethod",
    minWidth: 150,
  },
  {
    headerName: "Terminal ID",
    field: "terminalId",
    minWidth: 150,
  },
];

export const columnForLedgerTable = [
  {
    headerName: "Date",
    field: "Date",
    minWidth: 150,
  },
  {
    headerName: "Time",
    field: "Time",
    minWidth: 150,
  },
  {
    headerName: "Transaction ID",
    field: "To Customer/Vendor Name",
    minWidth: 150,
  },
  {
    headerName: "Particular",
    field: "Transfer Type",
    minWidth: 150,
  },
  {
    headerName: "Credit",
    field: "Particular(A/C or VPA)",
    minWidth: 150,
  },
  {
    headerName: "Debit",
    field: "Amount",
    minWidth: 150,
  },
  {
    headerName: "",
    field: "RRN",
    minWidth: 150,
  },
];

export const columnForGuaranteeTable = [
  {
    headerName: "Transaction Date",
    field: "transactionDate",
    minWidth: 150,
  },

  {
    headerName: "Guarantee Amount",
    field: "guarantyAmount",
    minWidth: 150,
  },
  {
    headerName: "Transaction Type",
    field: "transactionType",
    minWidth: 150,
  },
  {
    headerName: "Reference Number",
    field: "utrNumber",
    minWidth: 150,
  },
  {
    headerName: "Net Amount",
    field: "netAmount",
    minWidth: 150,
  },
];

export const columnforChargeback = [
  {
    headerName: "RRN",
    field: "rrn",
    minWidth: 150,
  },
  {
    headerName: "Chargeback Upload Date",
    field: "createDate",
    minWidth: 150,
  },
  {
    headerName: "Chargeback Upload Time",
    field: "createTime",
    minWidth: 150,
  },
  {
    headerName: "Chargeback Adjustment Date",
    field: "chargeBackAdjDate",
    minWidth: 150,
  },

  {
    headerName: "Chargeback Amount",
    field: "chargeBackAmount",
    minWidth: 150,
  },
  {
    headerName: "Compensation/Penalty",
    field: "compensationPenalty",
    minWidth: 150,
  },
  {
    headerName: "Transaction Date",
    field: "transactionDate",
    minWidth: 150,
  },
  {
    headerName: "Transaction Amount",
    field: "transactionAmount",
    minWidth: 150,
  },
  {
    headerName: "Payer VPA",
    field: "payerVpa",
    minWidth: 150,
  },
  {
    headerName: "Payer Bank",
    field: "payeeBank",
    minWidth: 150,
  },
  {
    headerName: "Transaction UID",
    field: "transactionUid",
    minWidth: 150,
  },
  {
    headerName: "Payer Mobile Number",
    field: "mobileNumber",
    minWidth: 150,
  },
  {
    headerName: "Payer e-Mail ID",
    field: "emailId",
    minWidth: 150,
  },
  {
    headerName: "Payer IFSC Code",
    field: "ifscCode",
    minWidth: 150,
  },
  {
    headerName: "Payer Bank Location",
    field: "bankLocation",
    minWidth: 150,
  },
  {
    headerName: "Payer IP Address",
    field: "payeeIpAddress",
    minWidth: 150,
  },
  {
    headerName: "Payer Location",
    field: "payeeLocation",
    minWidth: 150,
  },
  // {
  //   headerName: "Actions",
  //   field: "actionButtons",
  //   minWidth: 150,
  // },
];

export const columnforWalletBalance = [
  {
    headerName: "Date",
    field: "createDate",
    minWidth: 150,
  },
  {
    headerName: "Actual Amount",
    field: "requestedAmount",
    minWidth: 150,
  },
  {
    headerName: "Amount",
    field: "amount",
    minWidth: 150,
  },
  {
    headerName: "Service Charge",
    field: "serviceCharge",
    minWidth: 150,
  },
  {
    headerName: "GST on Service",
    field: "gstCharge",
    minWidth: 150,
  },
  {
    headerName: "UTR",
    field: "utr",
    minWidth: 150,
  },
  {
    headerName: "Transfer Mode",
    field: "transferMode",
    minWidth: 150,
  },
  {
    headerName: "Status",
    field: "status",
    minWidth: 150,
  },
];

export const columnforCreditBalance = [
  {
    headerName: "Date",
    field: "createDate",
    minWidth: 150,
  },
  {
    headerName: "Time",
    field: "createTime",
    minWidth: 150,
  },
  {
    headerName: "Amount",
    field: "orignalAmount",
    minWidth: 150,
  },
  {
    headerName: "Service Charge",
    field: "deductedAmount",
    minWidth: 150,
  },
  {
    headerName: "Charge In Percentage",
    field: "chargeInPercentage",
    minWidth: 150,
  },
  {
    headerName: "Charges For Service",
    field: "txnType",
    minWidth: 150,
  },
];

export const columnforCreditBalanceDebit = [
  {
    headerName: "Date",
    field: "createDate",
    minWidth: 150,
  },
  {
    headerName: "Time",
    field: "createTime",
    minWidth: 150,
  },
  {
    headerName: "Amount",
    field: "orignalAmount",
    minWidth: 150,
  },
  {
    headerName: "Remark",
    field: "txnType",
    minWidth: 250,
  },
];

export const columnforHoldAmount = [
  {
    headerName: "Amount",
    field: "amount",
    minWidth: 150,
  },
  {
    headerName: "Actual Amount",
    field: "actualAmount",
    minWidth: 150,
  },
  {
    headerName: "Hold Percentage",
    field: "holdPercentage",
    minWidth: 150,
  },

  {
    headerName: "Transaction ID",
    field: "txnId",
    minWidth: 150,
  },

  {
    headerName: "Created Date",
    field: "createDate",
    minWidth: 150,
  },
];

export const columnforDepositAmount = [
  {
    headerName: "Amount",
    field: "amount",
    minWidth: 150,
  },
  {
    headerName: "Actual Amount",
    field: "actualAmount",
    minWidth: 150,
  },
  {
    headerName: "Deposite Percentage",
    field: "depositePercentage",
    minWidth: 150,
  },

  {
    headerName: "Transaction ID",
    field: "txnId",
    minWidth: 150,
  },

  {
    headerName: "Date",
    field: "date",
    minWidth: 150,
  },
];

export const columnForFRMSubmittedUser = [
  {
    headerName: "ID",
    field: "id",
    minWidth: 150,
  },
  {
    headerName: "VPA",
    field: "vpa",
    minWidth: 150,
  },
  {
    headerName: "IFSC Code",
    field: "ifscCode",
    minWidth: 150,
  },
  {
    headerName: "E-mail",
    field: "email",
    minWidth: 150,
  },

  {
    headerName: "Mobile",
    field: "mobile",
    minWidth: 150,
  },
  {
    headerName: "IMEI / Device ID",
    field: "imei",
    minWidth: 150,
  },

  {
    headerName: "Latitude",
    field: "letitude",
    minWidth: 150,
  },

  {
    headerName: "Longitude",
    field: "longitude",
    minWidth: 150,
  },
];

export const columnForPayoutSection = [
  {
    headerName: "Vendor Name",
    field: "vendorName",
    minWidth: 150,
  },

  {
    headerName: "Type",
    field: "payoutFor",
    minWidth: 100,
  },
  {
    headerName: "Category",
    field: "category",
    minWidth: 100,
  },

  {
    headerName: "Mobile",
    field: "mobile",
    minWidth: 100,
  },
  {
    headerName: " e-Mail ID",
    field: "email",
    minWidth: 150,
  },
  {
    headerName: "VPA / UPI ID",
    field: "userVpa",
    minWidth: 100,
  },
  {
    headerName: "Account Number",
    field: "accountNumber",
    minWidth: 100,
  },
  {
    headerName: "IFSC Code",
    field: "ifscCode",
    minWidth: 100,
  },
  {
    headerName: "Amount In Rupees",
    field: "amount",
    minWidth: 100,
  },
];
